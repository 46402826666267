import React from "react"
import ReactDOM from "react-dom"
import App from "./views/App"
import configureStore from "./app/store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import "./sass/main.scss"

const { store, persistor } = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)