import React from "react"
import PropTypes from "prop-types"
import spinner_icon from "../../assets/icon/spinner.svg"

function Loading(props) {
    return (
        <>
        <div className={props.little ? "loading_component" : "loading_section"}>
            <img className="icon" src={spinner_icon} alt="" />
        </div>
        </>
    )
}

Loading.propTypes = {
    little: PropTypes.bool
}

export default Loading