import React from "react"
import { Navigate } from "react-router-dom"

function RequireAuth(props) {

  let auth = props.isLogin
  if (!auth) {
    return <Navigate to="/login" />
  }

  return props.children
}

export default RequireAuth