import React from "react"
import AllRoutes from "./AllRoutes"
import axios from "axios"
import { SWRConfig } from "swr"

function App() {
  return (
    <>
    <SWRConfig value={{refreshInterval: 30000, fetcher: (url, token) => axios.get(url, token).then(res => res.data)}}>
        <AllRoutes />
    </SWRConfig>
    </>
  )
}

export default App