import * as types from "../constants/postsConstants"

const posts = {
    modal: false,
    confirmation: false
}

const PostsReducer = (state = posts, action) => {
    switch(action.type) {
        case types.MODAL_CONFIRMATION:
            return {
                modal: action.payload.modal,
                postID: action.payload.postID
            }
        case types.DELETE_CONFIRMATION:
            return {
                modal: action.payload.modal,
                confirmation: action.payload.confirmation,
                postID: action.payload.postID
            }
        default:
            return state
    }
}

export default PostsReducer