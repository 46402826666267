import * as types from "../constants/navigationConstants"

const navigation = {
    onMobile: (window.innerWidth > 768 ? false : true),
    current: "advice"
}

const NavigationReducer = (state = navigation, action) => {
    switch(action.type) {
        case types.ON_MOBILE:
            return {
                ...state,
                onMobile: action.payload.onMobile,
            }
        case types.MOOVE:
            return {
                ...state,
                current: action.payload.current
            }
        default:
            return state
    }
}

export default NavigationReducer