import { createStore } from "redux"
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import SessionReducer from "../reducers/sessionReducer"
import PostsReducer from "../reducers/postsReducer"
import NavigationReducer from "../reducers/navigationReducer"

function configureStore() {

  const rootReducer = combineReducers({
    session: SessionReducer,
    posts: PostsReducer,
    navigation: NavigationReducer,
  })

  const persistConfig = {
    key: "root",
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  let store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  let persistor = persistStore(store)

  return { store, persistor }
}

export default configureStore