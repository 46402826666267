import * as types from "../constants/sessionConstants"

const session = {
    isLogin: false,
    token: undefined,
    role: undefined
}

const SessionReducer = (state = session, action) => {
    switch(action.type) {
        case types.LOGIN:
            return {
                isLogin: action.payload.isLogin,
                token: action.payload.token,
            }
        case types.LOGOUT:
            return {
                isLogin: action.payload.isLogin
            }
        case types.GET_PROFILE:
            return {
                isLogin: action.payload.isLogin,
                token: action.payload.token,
                profile: {
                    firstname: action.payload.firstname,
                    lastname: action.payload.lastname,
                    email: action.payload.email,
                    verify: action.payload.verify,
                    role: action.payload.role
                }
            }
        default:
            return state
    }
}

export default SessionReducer