import React, { Suspense, lazy } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { sessionSelector } from "../selectors/sessionSelector"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import RequireAuth from "../RequireAuth.js"
import Loading from "../components/loading/Loading"
const Home = lazy(() => import("./Home"))
const Services = lazy(() => import("./Services"))
const Articles = lazy(() => import("./Articles"))
const Article = lazy(() => import("./Article"))
const Contact = lazy(() => import("./Contact"))
const Legales = lazy(() => import("./Legales"))
const Error404 = lazy(() => import("./Error404"))
const Error503 = lazy(() => import("./Error503"))
const Login = lazy(() => import("./Login"))
const AdminPanel = lazy(() => import("./AdminPanel"))
const JobsPanel = lazy(() => import("./JobsPanel"))
const Jobs = lazy(() => import("./Jobs"))
const Job = lazy(() => import("./Job"))
const ArticlesPanel = lazy(() => import("./ArticlesPanel"))
const CreatePost = lazy(() => import("./CreatePost"))
const UpdateJob = lazy(() => import("./UpdateJob"))
const UpdateArticle = lazy(() => import("./UpdateArticle"))
const Header = lazy(() => import("../components/header/Header"))
const Footer = lazy(() => import("../components/footer/Footer"))
const ContactMobile = lazy(() => import("../components/contact_mobile/ContactMobile"))

function AllRoutes(props) {
    return (
        <>
        <Router>
            <Suspense fallback={<><Loading /></>}>
                <Header/>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="services" element={<Services />} />
                        <Route path="jobs" element={<Jobs />} />
                        <Route path="jobs/:jobID" element={<Job />} />
                        <Route path="articles" element={<Articles />} />
                        <Route path="articles/:articleID" element={<Article />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="mentions-legales"  element={<Legales />} />
                        <Route path="login"  element={<Login />} />
                        <Route path="admin-panel" element={<RequireAuth isLogin={props.session.isLogin}>
                            <AdminPanel />
                        </RequireAuth>} />
                        <Route path="admin-panel/articles"  element={<RequireAuth isLogin={props.session.isLogin}>
                            <ArticlesPanel />
                        </RequireAuth>} />
                        <Route path="articles/new" element={<RequireAuth isLogin={props.session.isLogin}>
                            <CreatePost />
                        </RequireAuth>} />
                        <Route path="articles/update/:articleID" element={<RequireAuth isLogin={props.session.isLogin}>
                            <UpdateArticle />
                        </RequireAuth>} />
                        <Route path="admin-panel/jobs"  element={<RequireAuth isLogin={props.session.isLogin}>
                            <JobsPanel />
                        </RequireAuth>} />
                        <Route path="jobs/new" element={<RequireAuth isLogin={props.session.isLogin}>
                            <CreatePost />
                        </RequireAuth>} />
                        <Route path="jobs/update/:jobID" element={<RequireAuth isLogin={props.session.isLogin}>
                            <UpdateJob />
                        </RequireAuth>} />
                        <Route path="/error503" element={<Error503 />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                <Footer/>
                {!props.session.isLogin ? <ContactMobile /> : ""}
            </Suspense>
        </Router>
    </>
  )
}

AllRoutes.propTypes = {
    session: PropTypes.object,
    dispatch: PropTypes.func
}

const AllRoutesStore = connect(sessionSelector)(AllRoutes)

export default AllRoutesStore